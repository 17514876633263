import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import { HiOutlineCursorClick } from "react-icons/hi";
import logo from "../assets/imgs/logos/logo.png";
import perfilPhoto from "../assets/imgs/perfil_karla.jpg";

export default function HomeFirstPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      const logoElem = document.querySelector(".home-logo");
      if (logoElem) {
        logoElem.style.display = "none";
      }
    }, 2000);
  }, []);

  function scrollDown() {
    const element = document.getElementById("projects");
    if (element) {
      const offset = element.offsetTop - 120;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }

  return (
    <section id="home">
      <Container>
        <div className="home-logo">
          <motion.img
            src={logo}
            alt="Logo"
            initial={{ y: "100vh", rotate: 0 }}
            animate={{
              y: "15vh",
              rotate: 360,
            }}
            transition={{ duration: 2, type: "spring", stiffness: 20 }}
          />
        </div>
        <Row>
          <Col xl={6} lg={12} md={12} sm={12} xs={12}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 2 }}
            >
              <h2 className="h2-home">Karla Alcantara</h2>
              <h3 className="h3-home">Product Design</h3>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 2 }}
            >
              <button
                className="ver-projetos-btn"
                type="button"
                onClick={() => scrollDown()}
              >
                Ver projetos <HiOutlineCursorClick />
              </button>
            </motion.div>
          </Col>
          <Col xl={6} lg={12} md={12} sm={12} xs={12}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 2 }}
            >
              <div className="home-photo">
                <div className="home-photo-img">
                  <img src={perfilPhoto} alt="Karla Alcantara" />
                </div>
                <div className="home-photo-border"></div>
              </div>
            </motion.div>
          </Col>
        </Row>
        <div className="scrolldown-arrow">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 2 }}
          >
            <IoIosArrowDown onClick={() => scrollDown()} />
          </motion.div>
        </div>
      </Container>
    </section>
  );
}
